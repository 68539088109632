import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import TestContent from './TestContent'; // Import the TestContent component
import { Link } from 'react-router-dom';
import './App.css';

const Home = () => {
  const { logout } = useAuth();
  const [content, setContent] = useState(null);

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error("Error logging out:", error);
      alert('Failed to log out. Please try again later.');
    }
  };

  const loadContent = () => {
    setContent(<TestContent />); // Load the TestContent component
  };

  return (
    <div className="page-content">
      <h1>Hanch.net Web App - Logged In</h1>
      <button onClick={handleLogout}>Logout</button>
      <div className="textlink" onClick={loadContent}>Open Page Below</div>
      <div className="textlink"><Link to="/marquee">Marquee</Link></div>
      <div className="textlink"><Link to="/containers">Song Containers</Link></div> 
      <div className="textlink"><Link to="/event-calendar">Event Calendar Management</Link></div> 
      <div className="textlink"><Link to="/test-calendar">Test Calendar</Link></div> 

      <div className="new-content">
        {content}
      </div>
    </div>
  );
};

export default Home;
