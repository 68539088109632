// TestPage.js
import React from 'react';

const EventCalendar = () => {
  return (
    <div>
      <h2>Welcome to the new page!</h2>
      <p>This page is rendered by the TestPage component.</p>
    </div>
  );
};

export default EventCalendar;
