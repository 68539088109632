import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const Containers = () => {
  const [items, setItems] = useState([]);
  const [expandedContainers, setExpandedContainers] = useState([]);

  const colors = ['#FF5733', '#1F618D', '#28B463', '#F4D03F', '#8E44AD', '#FF69B4', '#4B0082', '#40E0D0'];

  const onDragEnd = (result) => {
    if (result.combine) {
      const { source, combine } = result;

      const newItems = Array.from(items);
      const sourceContainer = source.droppableId === 'root'
        ? newItems
        : newItems.find(item => item.id === source.droppableId)?.children;

      const sourceItem = sourceContainer?.splice(source.index, 1)[0];
      if (!sourceItem) return;

      const destinationContainer = newItems.find(item => item.id === combine.draggableId);
      if (destinationContainer && destinationContainer.isContainer) {
        if (!destinationContainer.children) {
          destinationContainer.children = [];
        }
        destinationContainer.children.push(sourceItem);

        if (!expandedContainers.includes(destinationContainer.id)) {
          setExpandedContainers([...expandedContainers, destinationContainer.id]);
        }
      } else {
        // If the destination is not a container, add the item back to its original position
        if (source.droppableId === 'root') {
          newItems.splice(source.index, 0, sourceItem);
        } else {
          const originalContainer = newItems.find(item => item.id === source.droppableId);
          if (originalContainer && originalContainer.children) {
            originalContainer.children.splice(source.index, 0, sourceItem);
          }
        }
      }

      setItems(newItems);
      return;
    }

    if (!result.destination) {
      // Handle dragging outside of any container
      const { source } = result;
      const newItems = Array.from(items);
      let sourceItem;

      if (source.droppableId === 'root') {
        sourceItem = newItems.splice(source.index, 1)[0];
      } else {
        const sourceContainer = newItems.find(item => item.id === source.droppableId);
        if (!sourceContainer || !sourceContainer.children) return;
        sourceItem = sourceContainer.children.splice(source.index, 1)[0];
      }

      // Add the item to the end of the root level items
      newItems.push(sourceItem);
      setItems(newItems);
      return;
    }

    const { source, destination } = result;

    if (source.droppableId === destination.droppableId && source.index === destination.index) return;

    const newItems = Array.from(items);

    let movedItem;
    if (source.droppableId === 'root') {
      movedItem = newItems.splice(source.index, 1)[0];
    } else {
      const sourceContainer = newItems.find(item => item.id === source.droppableId);
      if (!sourceContainer || !sourceContainer.children) return;
      movedItem = sourceContainer.children.splice(source.index, 1)[0];

      // Collapse the source container if it has no items
      if (sourceContainer.children.length === 0) {
        setExpandedContainers(prev => prev.filter(id => id !== sourceContainer.id));
      }
    }

    if (destination.droppableId === 'root') {
      newItems.splice(destination.index, 0, movedItem);
    } else {
      const destinationContainer = newItems.find(item => item.id === destination.droppableId);
      if (!destinationContainer || !destinationContainer.children) return;
      destinationContainer.children.splice(destination.index, 0, movedItem);

      // Expand the destination container if it's not already expanded
      if (!expandedContainers.includes(destinationContainer.id)) {
        setExpandedContainers([...expandedContainers, destinationContainer.id]);
      }
    }

    setItems(newItems);
  };

  const addColoredItem = () => {
    const newItem = {
      id: `item-${Date.now()}`,
      content: `Item ${items.length + 1}`,
      color: colors[items.length % colors.length],
      isContainer: false,
    };
    setItems([...items, newItem]);
  };

  const addGreyItem = () => {
    const newItem = {
      id: `container-${Date.now()}`,
      color: '#333',
      isContainer: true,
      children: [],
    };
    setItems([...items, newItem]);
  };

  const renderDroppableItems = (droppableId, items, isContainer) => (
    <Droppable droppableId={droppableId} type="ITEM" isCombineEnabled>
      {(provided) => (
        <div
          ref={provided.innerRef}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'hidden',
            borderRadius: isContainer ? '4px' : 'none',
            gap: '5px',
            transition: 'min-height 0.2s ease-in-out, border 0.2s ease-in-out',
          }}
          {...provided.droppableProps}
        >
          {items.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    userSelect: 'none',
                    margin: 0,
                    minHeight: '40px',
                    width: '100%',
                    borderRadius: '8px',
                    backgroundColor: item.color,
                    color: 'white',
                    boxSizing: 'border-box',
                    ...provided.draggableProps.style,
                  }}
                >
                  {item.content}
                  {item.isContainer && expandedContainers.includes(item.id) && (
                    <div style={{ width: '100%' }}>
                      {renderDroppableItems(item.id, item.children, true)}
                    </div>
                  )}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h1>Document</h1>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="root" type="ITEM" isCombineEnabled>
          {(provided) => (
            <div
              ref={provided.innerRef}
              style={{
                padding: '20px 0px',
                border: '2px dashed #555',
                borderRadius: '16px',
                width: '400px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '5px',
                boxSizing: 'border-box'
              }}
              {...provided.droppableProps}
            >
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        padding: item.isContainer ? '20px 40px' : 'none',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        userSelect: 'none',
                        boxSizing: 'border-box',
                        minHeight: '40px',
                        width: item.isContainer ? `calc(100% - 40px)` : `calc(100% - 120px)`,
                        border: item.isContainer ? '1px solid #555': `none`,
                        borderRadius: '8px',
                        backgroundColor: item.color,
                        color: 'white',
                        ...provided.draggableProps.style,
                      }}
                    >
                      {item.content}
                      {item.isContainer && expandedContainers.includes(item.id) && (
                        <div style={{ width: '100%' }}>
                          {renderDroppableItems(item.id, item.children, true)}
                        </div>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div style={{ marginTop: '20px' }}>
        <FontAwesomeIcon
          icon={faPlus}
          onClick={addColoredItem}
          style={{
            marginRight: '0.5rem',
            padding: '0.5rem 1rem',
            color: 'white',
            border: 'none',
            borderRadius: '0.25rem',
            cursor: 'pointer',
          }}
        ></FontAwesomeIcon>
        <FontAwesomeIcon
          icon={faPlus}
          onClick={addGreyItem}
          style={{
            padding: '0.5rem 1rem',
            backgroundColor: '#777',
            color: 'white',
            border: 'none',
            borderRadius: '0.25rem',
            cursor: 'pointer',
          }}
        >
          Add Grey Container
        </FontAwesomeIcon>
      </div>
    </div>
  );
};

export default Containers;