// TestContent.js
import React from 'react';

const TestContent = () => {
  return (
    <div>
      <h2>This is the content loaded inside the current page.</h2>
      <p>This content is coming from the TestContent component.</p>
    </div>
  );
};

export default TestContent;
