// TestPage.js
import React from 'react';
import Marquee from './Marquee';
import HanchLogoGradient from './assets/images/HanchLogoGradient';

const TestMarquees = () => {
  return (
    <div>
      <HanchLogoGradient></HanchLogoGradient>
      <div>
        <h2>Welcome to the new page!</h2>
        <p>This page is rendered by the TestPage component.</p>
      </div>
      <div className="marquees-flex">
        <Marquee className="marquee"/>
        <Marquee className="marquee"/>
        <Marquee className="marquee"/>
        <Marquee className="marquee"/>
        <Marquee className="marquee"/>
        <Marquee className="marquee"/>
        <Marquee className="marquee"/>
        <Marquee className="marquee"/>
        <Marquee className="marquee"/>
        <Marquee className="marquee"/>
        <Marquee className="marquee"/>
        <Marquee className="marquee"/>
      </div>
    </div>
  );
};

export default TestMarquees;
