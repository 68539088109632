import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const PrivateRoute = ({ children }) => {
  const { currentUser, loading, logout, isLocalhost, isTester, } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error("Error logging out:", error);
      alert('Failed to log out. Please try again later.');
    }
  };

  if (loading) {
    return <div className="fullpage-loading"><FontAwesomeIcon icon={faSpinner} spinPulse /></div>;
  }

  if (!currentUser && !isLocalhost) {
    const returnUrl = encodeURIComponent(window.location.href);
    return <Navigate to={`https://account.hanch.net/login?returnUrl=${returnUrl}`} replace />;
  }

  if (!currentUser && isLocalhost) {
    return <div className="fullpage-loading">Login required.</div>;
  }

  if (currentUser && !isTester) {
    return (
      <div>
        <h1>Access denied :(</h1>
        <div>
          <button onClick={handleLogout}>Logout</button>
        </div>
      </div>
    );
  }

  return children;

};

export default PrivateRoute;
